import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project07() {
  const title = "印刷系団体ホームページ";
  const tags = ["受託制作", "デザイン", "コーディング"];
  const url = "https://www.jfpi.or.jp/JBFA";
  const type = "静的サイト";
  const used = ["HTML(JSX)", "CSS(SCSS)", "JavaScript", "webpack", "Adobe XD"];
  const texts = [
    "印刷系団体のホームページをリニューアルしました。",
    "旧サイトとほぼ同じ構成やコンテンツを維持しつつ、レスポンシブ対応とデザインのリニューアルが主な目的です。",
    "関係者が利用するサイトのため、メインビジュアルは不要で新着情報をすぐに表示させたいという要望がありましが、「情報」や「伝達」をイメージしたパーティクルと線を背景にしたファーストビューを提案し、とても気に入っていただけました。",
    "コーディングにはJSXをテンプレートエンジンとして利用し、webpackでビルドおよびバンドルしてファイルを生成しています。",
  ];
  const img = "form";
  const width = 1200;
  const height = 4094;
  const year = "2022年";

  const theme = useTheme();
  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      width={width}
      height={height}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        下層ページ
      </h3>
      <div
        css={css`
          margin-top: 1rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1.5rem;
          row-gap: 1.5rem;
          @media (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(1, 1fr);
          }
        `}
      >
        <picture>
          <source srcSet="img/form3.webp" type="image/webp" />
          <img
            src="img/form3.jpg"
            alt="印刷系団体ホームページの下層ページ1"
            width={1200}
            height={5634}
          />
        </picture>
        <picture>
          <source srcSet="img/form2.webp" type="image/webp" />
          <img
            src="img/form2.jpg"
            alt="印刷系団体ホームページの下層ページ2"
            width={1200}
            height={6844}
          />
        </picture>
      </div>
    </Layout2>
  );
}
