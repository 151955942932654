import React from "react";
import Layout2 from "../components/layout/Layout2";

export default function Project10() {
  const title = "食品LP";
  const tags = ["受託制作", "デザイン", "コーディング"];
  const url = "https://www.mitake-shokuhin.co.jp/kinakomochi-kinako_special";
  const type = "静的サイト";
  const used = ["HTML", "CSS", "JavaScript", "Adobe XD", "Adobe Illustrator"];
  const texts = [
    "きな粉の食品LPを制作しました。",
    "製品開発者の方の「アレンジをして楽しんで食べて欲しい商品」というお話から、パッケージのおもちのキャラクターを動かしたり、左右にきな粉をイメージした丸を降らすなど、サイト自体も楽しい印象になるデザインとしています。",
  ];
  const img = "kinako";
  const year = "2021年";

  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      year={year}
    >
      <></>
    </Layout2>
  );
}
