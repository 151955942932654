import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project04() {
  const title = "更生保護事業関連会社ホームページ";
  const tags = ["受託制作", "ディレクション", "デザイン", "コーディング"];
  const url = "https://himawari-service.co.jp";
  const type = "WordPress";
  const used = [
    "HTML(JSX)",
    "CSS(Tailwind CSS)",
    "JavaScript",
    "PHP",
    "webpack",
    "Adobe XD",
    "Adobe Illustrator",
  ];
  const texts = [
    "更生保護事業関連会社のホームページをWordPressで制作しました。",
    "制作作業の他に、ディレクションとして、ヒアリングと提案、スケジュール管理、サーバー会社との連絡、ワイヤーフレームの作成、マニュアルの作成といった作業を行いました。",
    "お客様は普段、FAXや電話で業務を行っており、パソコンに苦手意識強く、サイト制作には消極的でした。<br>しかし、サイト公開後は、Web上からダウンロードされた注文票で商品の注文が入り、利用者の保護司の方から「便利になった」「カタログがスマホから見れて助かる」といったお声もあり、最終的に「ホームページを作ってよかった」と言っていただけました。",
    "コーディング環境ではJSXをテンプレートエンジンとし、CSSはTailwind CSSを使用しました。webpackでビルドおよびバンドルしたファイルをテーマ化しています。",
  ];
  const img = "himawari";
  const width = 1200;
  const height = 4674;
  const year = "2023年";

  const theme = useTheme();
  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      width={width}
      height={height}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        下層ページ
      </h3>
      <div
        css={css`
          margin-top: 1rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1.5rem;
          row-gap: 1.5rem;
          @media (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(1, 1fr);
          }
        `}
      >
        <picture>
          <source srcSet="img/himawari2.webp" type="image/webp" />
          <img
            src="img/himawari2.jpg"
            alt="更生保護事業関連会社ホームページの下層ページ1"
            width={1200}
            height={3012}
          />
        </picture>
        <picture>
          <source srcSet="img/himawari3.webp" type="image/webp" />
          <img
            src="img/himawari3.jpg"
            alt="更生保護事業関連会社ホームページの下層ページ2"
            width={1200}
            height={2498}
          />
        </picture>
      </div>
    </Layout2>
  );
}
