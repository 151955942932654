import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout from "../components/layout/Layout";

const listStyle = css({
  fontSize: "0.9375rem",
  margin: "0.75rem 0",
  padding: "0 0 0 1rem",
  position: "relative",
  lineHeight: 1.5,
  "&::before": {
    content: "''",
    position: "absolute",
    top: "0.45rem",
    left: 0,
    width: "0.4rem",
    height: "0.4rem",
    borderRadius: "999px",
    backgroundColor: "#fff",
  },
});

const listStyle2 = css({
  fontSize: "0.9375rem",
  fontWeight: 500,
  margin: "0.15rem 0",
  padding: "0 0 0 1.5rem",
  position: "relative",
  lineHeight: 1.5,
  "&::before": {
    content: "'⇒'",
    position: "absolute",
    fontSize: "0.9375rem",
    top: 0,
    left: 0,
  },
});

const h3Style = css({
  fontSize: "clamp(1.15rem, 5vw, 1.4rem)",
  fontWeight: 700,
  lineHeight: 1.4,
  paddingBottom: "0.5rem",
  borderBottom: "1px solid #fff",
  paddingLeft: "2rem",
  textIndent: "-2.25rem",
});

export default function Project01() {
  const title = "スケジュール&記録アプリ";
  const tags = ["自主制作", "デザイン", "コーディング"];
  const url = "https://scheduler.1coffee9milk.com";
  const pass =
    "【テストアカウント】<br/>&nbsp;UserName：admin<br/>&nbsp;Password：test123";
  const github = "https://github.com/aoto-me/scheduleApp";
  const type = "React";
  const used = [
    "HTML(TSX)",
    "CSS(Material UI)",
    "JavaScript(TypeScript)",
    "PHP",
    "MySQL",
    "Adobe XD",
  ];
  const texts = [
    "自分用にスケジュールやメモ、各種記録用のWEBアプリを制作しました。<br/>スマートフォンではネイティブアプリのように扱いたかったので、PWAを利用しています。",
    "機能や仕様は、下記の通りです。",
  ];
  const img = "app_tmb";
  const year = "2024年";

  const theme = useTheme();

  return (
    <Layout
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      github={github}
      pass={pass}
      year={year}
    >
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={1318}
          height={871}
          preload="auto"
          poster="img/app2.webp"
        >
          <source src="img/app2.webm" type="video/webm" />
          <source src="img/app2.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>１. ログインページ</h3>
          <ul className="font-jp">
            <li css={listStyle}>ユーザー別のログイン・ログアウト機能</li>
            <li css={listStyle}>暗号化したパスワードと照合</li>
            <li css={listStyle}>JWT認証によるログイン状態の維持</li>
            <li css={listStyle}>
              ログイン時にCSRF対策用の専用トークン発行<br></br>
              （以降、データ送信時にトークンをチェック）
            </li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={1318}
          height={872}
          preload="auto"
          poster="img/app3.webp"
        >
          <source src="img/app3.webm" type="video/webm" />
          <source src="img/app3.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>２. カレンダーページ</h3>
          <ul className="font-jp">
            <li css={listStyle}>
              月別カレンダー（FullCalendar）
              <ul>
                <li css={listStyle2}>日付クリックで選択日の詳細情報を表示</li>
                <li css={listStyle2}>
                  Todo、Money、Healthの項目別の絞込み表示
                </li>
              </ul>
            </li>
            <li css={listStyle}>
              右サイドバーで選択日のTodo、Money、Healthの詳細情報の表示と追加、編集
            </li>
            <li css={listStyle}>
              フォームの入力内容のバリデーションとエラーメッセージの表示（Zod、React
              Hook Form）
            </li>
            <li css={listStyle}>
              マークダウン記法の月別メモ（react-markdown）
            </li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={1318}
          height={872}
          preload="auto"
          poster="img/app4.webp"
        >
          <source src="img/app4.webm" type="video/webm" />
          <source src="img/app4.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>３. プロジェクトページ</h3>
          <ul className="font-jp">
            <li css={listStyle}>プロジェクトの追加・編集・削除</li>
            <li css={listStyle}>
              カンバンボードによるタスクの管理
              <ul>
                <li css={listStyle2}>
                  セクション（カンバンボードの分類）、タスク（Todoと共通）の追加・編集・削除
                </li>
                <li css={listStyle2}>
                  セクションとタスクをドラッグアンドドロップで移動（React DnD）
                </li>
              </ul>
            </li>
            <li css={listStyle}>
              全タスクの作業推定時間と実作業時間の比較グラフ（chart.js）
            </li>
            <li css={listStyle}>
              マークダウン記法のプロジェクトメモ（react-markdown）
            </li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={1338}
          height={868}
          preload="auto"
          poster="img/app9.webp"
        >
          <source src="img/app9.webm" type="video/webm" />
          <source src="img/app9.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>４. メモページ</h3>
          <ul className="font-jp">
            <li css={listStyle}>メモの追加・編集・削除</li>
            <li css={listStyle}>マークダウン記法のメモ（react-markdown）</li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={1309}
          height={868}
          preload="auto"
          poster="img/app5.webp"
        >
          <source src="img/app5.webm" type="video/webm" />
          <source src="img/app5.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>４. Todoページ</h3>
          <ul className="font-jp">
            <li css={listStyle}>
              選択月に登録されているTodoの一覧表
              <ul>
                <li css={listStyle2}>
                  分類別（仕事、プライベート、ルーティン）の絞込み表示
                </li>
                <li css={listStyle2}>複数選択による登録内容の削除</li>
              </ul>
            </li>
            <li css={listStyle}>
              カレンダー下と共通のマークダウン記法の月別メモ（react-markdown）
            </li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={1309}
          height={868}
          preload="auto"
          poster="img/app6.webp"
        >
          <source src="img/app6.webm" type="video/webm" />
          <source src="img/app6.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>５. Moneyページ</h3>
          <ul className="font-jp">
            <li css={listStyle}>
              選択月に登録されているMoneyデータから収入、支出、収支の合計を表示
            </li>
            <li css={listStyle}>
              月の収入、支出のカテゴリー別の円グラフの表示（chart.js）
            </li>
            <li css={listStyle}>
              年間の各月の収入、支出、収支の累計グラフの表示（chart.js）
            </li>
            <li css={listStyle}>
              選択月に登録されているMoneyデータの一覧表
              <ul>
                <li css={listStyle2}>
                  タイプ別（収入、支出）やカテゴリー別（食費や住居費など）の絞込み表示
                </li>
                <li css={listStyle2}>複数選択による登録内容の削除</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={1309}
          height={868}
          preload="auto"
          poster="img/app7.webp"
        >
          <source src="img/app7.webm" type="video/webm" />
          <source src="img/app7.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>６. Healthページ</h3>
          <ul className="font-jp">
            <li css={listStyle}>
              選択月に登録されているHealthデータからの平均睡眠時間と平均体重を算出して表示
            </li>
            <li css={listStyle}>
              月または年間の睡眠時間と体重の変動グラフ（chart.js）
            </li>
            <li css={listStyle}>
              選択月に登録されているHealthデータの一覧表
              <ul>
                <li css={listStyle2}>複数選択による登録内容の削除</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={1309}
          height={868}
          preload="auto"
          poster="img/app8.webp"
        >
          <source src="img/app8.webm" type="video/webm" />
          <source src="img/app8.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>７. 検索ページ</h3>
          <ul className="font-jp">
            <li css={listStyle}>
              登録されている全てのデータ（月別メモ、プロジェクト、メモ、Todo、Money、Health）の中からキーワードでAND・OR検索
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}
