import { createContext, useContext, useState } from "react";

interface CommonContextType {
  scrollPosition: { x: number; y: number };
  setScrollPosition: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
  bgLoaded: boolean;
  setBgLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommonContext = createContext<CommonContextType | undefined>(
  undefined,
);

export const CommonContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const [bgLoaded, setBgLoaded] = useState(false);

  return (
    <CommonContext.Provider
      value={{
        scrollPosition,
        setScrollPosition,
        bgLoaded,
        setBgLoaded,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonContext = () => {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error("CommonContextをプロバイダーの中で取得してください");
  }
  return context;
};
