import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project11() {
  const title = "紙製食品トレイLP";
  const tags = ["受託制作", "デザイン", "コーディング"];
  const url = "https://lp.graphicpkg.jp";
  const type = "静的サイト";
  const used = ["HTML", "CSS", "JavaScript", "Adobe XD", "Adobe Illustrator"];
  const texts = [
    "紙製食品トレイのLPを制作しました。",
    "製品の強みを簡潔に説明して、パッと見終わるサイトにしたいという要望から、写真やイラストをメインに見せるようなデザインとし、より詳細情報が欲しいユーザーに向けてはモーダルで補足をする構成としました。",
    "環境へやさしい製品であることがサイトから伝わるように、明るい緑色や角丸、ムラのある手書き風のイラストを使用しています。",
  ];
  const img = "paper";
  const year = "2021年";

  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        補足説明用のモーダル
      </h3>
      <div
        css={css`
          margin: 1rem auto 0;
          max-width: 600px;
        `}
      >
        <picture>
          <source srcSet="img/paper2.webp" type="image/webp" />
          <img
            src="img/paper2.jpg"
            alt="紙製食品トレイLPのモーダル"
            width={1200}
            height={2508}
          />
        </picture>
      </div>
    </Layout2>
  );
}
