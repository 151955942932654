import React, { useEffect, useRef, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useCommonContext } from "../context/CommonContext";

gsap.registerPlugin(ScrollTrigger);

const links = [
  {
    to: "/project01",
    label: "スケジュール&記録アプリ",
    imgSrc: "./img/app_tmb",
    category: "自主制作",
    tags: ["React", "デザイン", "コーディング"],
  },
  {
    to: "/project02",
    label: "Pythonによる社内の業務改善",
    imgSrc: "./img/python_tmb",
    category: "業務改善",
    tags: ["デスクトップアプリ", "Python"],
  },
  {
    to: "/project03",
    label: "千代田区外郭団体ホームページ",
    imgSrc: "./img/machimirai_tmb",
    category: "受託制作",
    tags: ["WordPress", "コンペ", "ディレクション", "デザイン", "コーディング"],
  },
  {
    to: "/project04",
    label: "更生保護事業関連会社ホームページ",
    imgSrc: "./img/himawari_tmb",
    category: "受託制作",
    tags: ["WordPress", "ディレクション", "デザイン", "コーディング"],
  },
  {
    to: "/project05",
    label: "商社採用サイト",
    imgSrc: "./img/utsu_tmb",
    category: "受託制作",
    tags: ["Next.js", "デザイン", "コーディング(静的)"],
  },
  {
    to: "/project06",
    label: "絵画教室ホームページ",
    imgSrc: "./img/atelier_tmb",
    category: "受託制作",
    tags: ["静的サイト", "デザイン", "コーディング"],
  },
  {
    to: "/project07",
    label: "印刷系団体ホームページ",
    imgSrc: "./img/form_tmb",
    category: "受託制作",
    tags: ["静的サイト", "デザイン", "コーディング"],
  },
  {
    to: "/project08",
    label: "ロボットリース会社ホームページ",
    imgSrc: "./img/tg_tmb",
    category: "受託制作",
    tags: ["静的サイト", "デザイン", "コーディング"],
  },
  {
    to: "/project09",
    label: "食品系イベントサイト",
    imgSrc: "./img/next_tmb",
    category: "受託制作",
    tags: ["WordPress", "デザイン", "コーディング"],
  },
  {
    to: "/project10",
    label: "食品LP",
    imgSrc: "./img/kinako_tmb",
    category: "受託制作",
    tags: ["静的サイト", "デザイン", "コーディング"],
  },
  {
    to: "/project11",
    label: "紙製食品トレイLP",
    imgSrc: "./img/paper_tmb",
    category: "受託制作",
    tags: ["静的サイト", "デザイン", "コーディング"],
  },
  {
    to: "/project12",
    label: "木型のECサイト",
    imgSrc: "./img/made_tmb",
    category: "受託制作",
    tags: ["Shopify", "デザイン", "コーディング（テーマのカスタマイズ）"],
  },
];

function Home() {
  const pagesWrapperRef = useRef<HTMLDivElement | null>(null);
  const pagesRef = useRef<HTMLUListElement | null>(null);
  const didEffect = React.useRef(false);
  const { setScrollPosition, scrollPosition, bgLoaded } = useCommonContext();
  const [loading, setLoading] = useState(false);
  const [animeted, setAnimeted] = useState(false);
  const getScrollPosition = () => ({ x: window.scrollX, y: window.scrollY });

  useEffect(() => {
    if (bgLoaded) {
      setTimeout(() => {
        setLoading(true);
        setTimeout(() => {
          setAnimeted(true);
        }, 1000);
      }, 1000);
    }
    // クリーンアップ用にリスナーを削除
    // return () => {};
  }, [bgLoaded]);

  const setupGsap = (
    pagesElement: HTMLUListElement,
    pagesWrapperElement: HTMLDivElement,
  ) => {
    gsap.to(pagesElement, {
      x: () => -(pagesElement.clientWidth - pagesWrapperElement.clientWidth),
      ease: "none",
      scrollTrigger: {
        trigger: "#horizontal-scroll-section",
        start: "top top",
        end: () =>
          `+=${pagesElement.clientWidth - pagesWrapperElement.clientWidth}`,
        scrub: true,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
      },
    });
  };

  useEffect(() => {
    const pagesElement = pagesRef?.current;
    if (!pagesElement) return;
    const pagesWrapperElement = pagesWrapperRef?.current;
    if (!pagesWrapperElement) return;

    // 画像のロードを監視する関数
    const waitForImagesToLoad = (
      element: HTMLUListElement,
      callback: () => void,
    ) => {
      const images = element.querySelectorAll("img");
      let loadedCount = 0;
      const totalImages = images.length;

      if (totalImages === 0) {
        // 画像がない場合はすぐにcallbackを呼び出す
        callback();
        return;
      }

      images.forEach((img) => {
        if (img.complete) {
          // すでに読み込まれている画像
          loadedCount += 1;
          if (loadedCount === totalImages) {
            callback();
          }
        } else {
          // 画像が読み込まれていない場合はloadイベントを監視
          img.addEventListener("load", () => {
            loadedCount += 1;
            if (loadedCount === totalImages) {
              callback();
            }
          });
          // エラーの場合でもカウントする
          img.addEventListener("error", () => {
            loadedCount += 1;
            if (loadedCount === totalImages) {
              callback();
            }
          });
        }
      });
    };

    const scrollPositionSession = () => {
      if (scrollPosition.y > 0) {
        window.scrollTo(scrollPosition.x, scrollPosition.y);
      }
    };

    // 画像が読み込まれてからsetupGsapを呼び出す
    waitForImagesToLoad(pagesElement, () => {
      if (didEffect.current) return;
      didEffect.current = true;
      setupGsap(pagesElement, pagesWrapperElement);
      scrollPositionSession();
    });
  }, []);

  const handleClick = () => {
    // スクロール位置を保存
    const position = getScrollPosition();
    setScrollPosition(position);
  };

  return (
    <>
      <div
        css={css`
          min-height: calc(100vh + 1px);
        `}
      >
        <section
          css={css`
            width: calc(100% - min(9vw, 4rem));
            height: 100vh;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          `}
          id="horizontal-scroll-section"
        >
          <div
            css={css`
              width: 100%;
              position: relative;
              margin-top: clamp(90px, 15vh, 150px);
              height: calc(100vh - clamp(90px, 15vh, 150px));
            `}
            ref={pagesWrapperRef}
          >
            <ul
              css={css`
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 min(6vw, 2rem);
              `}
              ref={pagesRef}
              id="pages"
            >
              {links.map((link, index) => (
                <motion.li
                  className={`${bgLoaded ? "cardLine" : ""}${animeted ? " animeted" : ""}`}
                  key={index}
                  css={css`
                    margin: 5vh 2.5rem 0;
                  `}
                  initial={{
                    backgroundColor: "rgba(255, 255, 255, 0)",
                  }}
                  animate={{
                    backgroundColor: loading
                      ? "rgba(255, 255, 255, 0.21)"
                      : "rgba(255, 255, 255, 0)",
                    transition: {
                      duration: 0.35,
                      ease: "easeInOut",
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.4, ease: "easeInOut" },
                  }}
                >
                  <Link
                    css={css`
                      display: block;
                      height: 100%;
                      padding: 0.75rem;
                      text-decoration: none;
                    `}
                    to={link.to}
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <motion.div
                      css={css`
                        position: relative;
                        opacity: 0;
                      `}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: loading ? 1 : 0,
                        transition: {
                          duration: 0.5,
                          delay: 0.75,
                          ease: "easeInOut",
                        },
                      }}
                    >
                      <picture>
                        <source
                          srcSet={`${link.imgSrc}.webp`}
                          type="image/webp"
                        />
                        <img
                          css={css`
                            display: block;
                            height: 40vh;
                            width: auto;
                            max-width: none;
                            position: relative;
                            z-index: 1;
                          `}
                          src={`${link.imgSrc}.jpg`}
                          alt={`${link.label}のサムネイル`}
                          width={900}
                          height={600}
                        />
                      </picture>
                      <span
                        className="font-en"
                        css={css`
                          font-style: italic;
                          position: absolute;
                          bottom: 95%;
                          right: 92%;
                          z-index: 2;
                          font-size: clamp(1.5rem, 9vh, 7rem);
                          color: #fff;
                          line-height: 1;
                          text-align: center;
                          border-bottom: 3px solid #fff;
                        `}
                      >
                        {index + 1 < 10 ? `0${index + 1}` : index + 1}
                      </span>
                      <span
                        css={css`
                          border-radius: 999px;
                          font-weight: 500;
                          position: absolute;
                          font-size: clamp(0.85rem, 3vh, 1.3rem);
                          z-index: 2;
                          bottom: -0.75rem;
                          right: -1.75rem;
                          border: 1px solid #fff;
                          padding: 0.75rem 1.5rem;
                          color: #fff;
                          background: #20294f;
                          box-shadow:
                            rgba(200, 200, 200, 0.4) 5px 5px,
                            rgba(200, 200, 200, 0.2) 10px 10px;
                            rgba(200, 200, 200, 0.05) 15px 15px,
                        `}
                      >
                        {link.category}
                      </span>
                    </motion.div>
                    <motion.h2
                      css={css`
                        line-height: 1.35;
                        font-weight: 700;
                        font-size: clamp(1.25rem, 4vh, 1.85rem);
                        margin: clamp(1.25em, 4vh, 1.75rem) 0 0.5rem;
                        color: #fff;
                        opacity: 0;
                      `}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: loading ? 1 : 0,
                        transition: {
                          duration: 0.5,
                          delay: 0.75,
                          ease: "easeInOut",
                        },
                      }}
                    >
                      {link.label}
                    </motion.h2>
                    <motion.ul
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                        opacity: 0;
                      `}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: loading ? 1 : 0,
                        transition: {
                          duration: 0.5,
                          delay: 0.75,
                          ease: "easeInOut",
                        },
                      }}
                    >
                      {link.tags.map((tag, tagIndex) => (
                        <li
                          key={tagIndex}
                          css={css`
                            color: #fff;
                            font-size: clamp(0.75rem, 2vh, 1rem);
                            margin-right: 0.5rem;
                            margin-top: 0.5rem;
                            padding: 0.25rem 0.5rem 0.25rem;
                            background: #ffffff47;
                            border-radius: 3px;
                            line-height: 1;
                          `}
                        >
                          {tag}
                        </li>
                      ))}
                    </motion.ul>
                  </Link>
                </motion.li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
