import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project09() {
  const title = "食品系イベントサイト";
  const tags = ["受託制作", "デザイン", "コーディング"];
  const url = "https://www.nextable.info";
  const type = "WordPress";
  const used = [
    "HTML(JSX)",
    "CSS(SCSS)",
    "JavaScript",
    "PHP",
    "webpack",
    "Adobe XD",
    "Adobe Illustrator",
  ];
  const texts = [
    "食品系イベントのサイトをWordPressで制作しました。",
    "普段は食品関係の情報を発信し、イベント直近になるとその年の開催内容に合わせたページやメニューの追加を行うなど、頻繁にサイト内容が変化しますが、お客様自身で柔軟に運用していただいています。",
    "コーディング環境では、JSXをテンプレートエンジン代わりに利用し、webpackでビルドおよびバンドルしたファイルをテーマ化しました。",
  ];
  const img = "next";
  const year = "2022年";

  const theme = useTheme();

  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        下層ページ
      </h3>
      <div
        css={css`
          margin-top: 1rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1.5rem;
          row-gap: 1.5rem;
          @media (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(1, 1fr);
          }
        `}
      >
        <picture>
          <source srcSet="img/next3.webp" type="image/webp" />
          <img
            src="img/next3.jpg"
            alt="食品系イベントサイトの下層ページ1"
            width={1200}
            height={2608}
          />
        </picture>
        <picture>
          <source srcSet="img/next2.webp" type="image/webp" />
          <img
            src="img/next2.jpg"
            alt="食品系イベントサイトの下層ページ2"
            width={1200}
            height={3416}
          />
        </picture>
      </div>
    </Layout2>
  );
}
