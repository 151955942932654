import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project06() {
  const title = "絵画教室ホームページ";
  const tags = ["受託制作", "デザイン", "コーディング"];
  const url = "https://atelier-polish.com";
  const type = "静的サイト";
  const used = [
    "HTML(Pug)",
    "CSS(Tailwind CSS)",
    "JavaScript",
    "webpack",
    "Adobe XD",
  ];
  const texts = [
    "絵画教室のホームページを制作しました。",
    "地域＋絵画教室で検索した際に、上位に表示されることを目指して制作したサイトです。",
    "Googleビジネスアカウントへの登録の他、metaタグや支給のない部分のテキストをSEOを意識して埋めたり、セマンティックなコーディングを心掛けるなどしました。",
    "現在では、「港区 絵画教室」や「青山 絵画教室」で検索すると、Google検索で1、2番目に表示されています。",
    "テンプレートエンジンにPug、CSSにTailwind CSSを利用しており、webpackでビルドおよびバンドルしました。",
  ];
  const img = "atelier";
  const width = 1200;
  const height = 6138;
  const year = "2022年";

  const theme = useTheme();
  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      width={width}
      height={height}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        下層ページ
      </h3>
      <div
        css={css`
          margin-top: 1rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1.5rem;
          row-gap: 1.5rem;
          @media (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(1, 1fr);
          }
        `}
      >
        <picture>
          <source srcSet="img/atelier3.webp" type="image/webp" />
          <img
            src="img/atelier3.jpg"
            alt="絵画教室ホームページの下層ページ1"
            width={1200}
            height={4986}
          />
        </picture>
        <picture>
          <source srcSet="img/atelier2.webp" type="image/webp" />
          <img
            src="img/atelier2.jpg"
            alt="絵画教室ホームページの下層ページ2"
            width={1200}
            height={4406}
          />
        </picture>
      </div>
    </Layout2>
  );
}
