import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project03() {
  const title = "千代田区外郭団体ホームページ";
  const tags = [
    "受託制作",
    "コンペ",
    "ディレクション",
    "デザイン",
    "コーディング",
  ];
  const url = "https://www.mm-chiyoda.or.jp";
  const type = "WordPress";
  const used = [
    "HTML",
    "CSS(SCSS)",
    "JavaScript",
    "PHP",
    "Adobe XD",
    "Adobe Illustrator",
  ];
  const texts = [
    "千代田区内の制作会社の中からコンペで採用され、区の外郭団体ホームページをリニューアルしました。",
    "プレゼンテーションからディレクション、デザイン、コーディングなど公開までの作業を約半年間、ほとんど一人で担当しました。",
    "リニューアル前の主な課題は以下の通りです：<br>① レスポンシブ対応がされていない<br>② サイトの印象が堅い<br>③ 事業が複数あり、どの事業のページか分かりにくい<br>④ WordPressの管理画面からお客様が更新できないページがある（仕様的にできない、更新の方法が分からない）",
    "また、十数年にわたり様々なコンテンツが追加され、3000ページを超えており、全体の構成やコンテンツの整理に最も時間を割きました。",
    "解決策として：<br>① 全ページレスポンシブに対応<br>② 柔らかな色とイラストを用いた親しみやすいデザインに変更<br>③ 事業毎にページの色分けやメガメニューを用いて、コンテンツの分類を明確化<br>④ 専用のカスタム投稿やカスタムフィールドを用意し、ほとんどのページを更新できるようにWordPressをカスタマイズ。また、詳細な操作マニュアルを作成し、リニューアル後はお客様側でのサイト運営を実現",
    "これにより、課題を解決し、使いやすいサイトに生まれ変わりました。",
  ];
  const img = "machimirai";
  const width = 1200;
  const height = 4850;
  const year = "2023年";

  const theme = useTheme();
  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      width={width}
      height={height}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        下層ページ
      </h3>
      <div
        css={css`
          margin-top: 1rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1.5rem;
          row-gap: 1.5rem;
          @media (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(1, 1fr);
          }
        `}
      >
        <picture>
          <source srcSet="img/machimirai2.webp" type="image/webp" />
          <img
            src="img/machimirai2.jpg"
            alt="千代田区外郭団体ホームページの下層ページ1"
            width={1200}
            height={3474}
          />
        </picture>
        <picture>
          <source srcSet="img/machimirai3.webp" type="image/webp" />
          <img
            src="img/machimirai3.jpg"
            alt="千代田区外郭団体ホームページの下層ページ2"
            width={1200}
            height={2972}
          />
        </picture>
      </div>
    </Layout2>
  );
}
