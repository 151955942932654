const theme = {
  breakpoints: {
    sm: "600px",
    md: "768px",
    lg: "1024px",
    xl: "1200px",
  },
};

export default theme;
