import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project05() {
  const title = "商社採用サイト";
  const tags = ["受託制作", "デザイン", "コーディング(静的)"];
  const url = "https://www.utsu-saiyou.com";
  const type = "Next.js（担当はNext.jsの開発前まで）";
  const used = [
    "HTML(JSX)",
    "CSS(Tailwind CSS)",
    "JavaScript",
    "webpack",
    "Adobe XD",
  ];
  const texts = [
    "開発会社からの依頼で、商社採用サイトのデザインとNext.js化のための静的コーディングを担当しました。<br>また、サイトで使用する写真の撮影において、カメラマンへの撮影指示も行いました。",
    "CSSは依頼元の指定でTailwind CSSを使用し、HTMLはJSXをテンプレートエンジン代わりに利用し、webpackでビルドおよびバンドルしています。",
  ];
  const img = "utsu";
  const width = 1200;
  const height = 7694;
  const year = "2023年";

  const theme = useTheme();
  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      width={width}
      height={height}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        下層ページ
      </h3>
      <div
        css={css`
          margin-top: 1rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1.5rem;
          row-gap: 1.5rem;
          @media (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(1, 1fr);
          }
        `}
      >
        <picture>
          <source srcSet="img/utsu2.webp" type="image/webp" />
          <img
            src="img/utsu2.jpg"
            alt="商社採用サイトの下層ページ1"
            width={1200}
            height={8574}
          />
        </picture>
        <picture>
          <source srcSet="img/utsu3.webp" type="image/webp" />
          <img
            src="img/utsu3.jpg"
            alt="商社採用サイトの下層ページ2"
            width={1200}
            height={6986}
          />
        </picture>
      </div>
    </Layout2>
  );
}
