import React, { useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import * as THREE from "three";
import { useMatches, useOutlet, Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useCommonContext } from "./context/CommonContext";

interface GlTex {
  plane: {
    mesh: THREE.Mesh | null;
    geometry: THREE.PlaneGeometry | null;
    material: THREE.ShaderMaterial | null;
    width: number;
    height: number;
    a: {
      texture: THREE.Texture | null;
      src: string | null;
    };
  };
  loadAssets: () => void;
  init: () => void;
  addPlane: () => void;
  update: () => void;
  camera?: THREE.OrthographicCamera;
  scene?: THREE.Scene;
  stage: THREE.Group | null;
  width: number;
  height: number;
  aspect?: number;
}

interface WebGL {
  ready: boolean;
  width: number;
  height: number;
  bg: HTMLElement | null;
  canvas: HTMLCanvasElement | null;
  pixelRatio: number;
  renderer: THREE.WebGLRenderer | null;
  manager: THREE.LoadingManager | null;
  glTex: GlTex | null;
  init: () => void;
  resize: () => void;
}

function App() {
  const { bgLoaded, setBgLoaded } = useCommonContext();
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  useEffect(() => {
    const getWindowHeight = () => {
      setWindowHeight(window.innerHeight);
    };

    // チェック
    getWindowHeight();

    // リサイズイベントのリスナーを追加
    window.addEventListener("resize", getWindowHeight);

    // クリーンアップ用にリスナーを削除
    return () => {
      window.removeEventListener("resize", getWindowHeight);
    };
  }, []);

  useEffect(() => {
    const webgl: WebGL = {
      ready: false,
      width: 0,
      height: 0,
      bg: null,
      canvas: null,
      pixelRatio: 0,
      renderer: null,
      manager: null,
      glTex: null,
      init() {
        this.bg = document.querySelector(".bgArea");
        this.canvas = document.getElementById("js-webgl") as HTMLCanvasElement;
        this.width = this.bg?.clientWidth || window.innerWidth;
        this.height = this.bg?.clientHeight || window.innerHeight;
        this.pixelRatio = Math.min(window.devicePixelRatio, 1.2);
        this.renderer = new THREE.WebGLRenderer({
          canvas: this.canvas,
          alpha: false,
          antialias: true,
          preserveDrawingBuffer: false,
        });
        this.renderer.setPixelRatio(this.pixelRatio);
        this.renderer.setSize(this.width, this.height);
        this.manager = new THREE.LoadingManager();
        this.manager.onLoad = () => {
          this.glTex?.init();
          this.ready = true;
          const animate = () => {
            if (
              webgl.ready &&
              webgl.glTex &&
              webgl.glTex.scene &&
              webgl.glTex.camera
            ) {
              webgl.glTex?.update();
              webgl.renderer?.render(webgl.glTex.scene, webgl.glTex.camera);
              requestAnimationFrame(animate);
            }
          };
          animate();
        };
      },
      resize() {
        this.width = this.bg?.clientWidth || window.innerWidth;
        this.height = this.bg?.clientHeight || window.innerHeight;
        this.renderer?.setSize(this.width, this.height);
      },
    };

    webgl.glTex = {
      plane: {
        mesh: null,
        geometry: null,
        material: null,
        width: 1200,
        height: 800,
        a: {
          texture: null,
          src: null,
        },
      },
      stage: null,
      width: 0,
      height: 0,
      loadAssets() {
        const textureLoader = new THREE.TextureLoader(
          webgl.manager as THREE.LoadingManager,
        );
        textureLoader.crossOrigin = "anonymous";
        this.plane.a.src = document.getElementById("js-webgl")?.dataset
          .src as string;
        textureLoader.load(this.plane.a.src, (texture) => {
          /* eslint-disable */
          texture.minFilter = THREE.LinearFilter;
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          this.plane.a.texture = texture;
          /* eslint-disable */
        });
      },
      init() {
        this.width = webgl.width;
        this.height = webgl.height;
        this.aspect = this.width / this.height;
        this.camera = new THREE.OrthographicCamera(
          -this.width / 3,
          this.width / 3,
          this.height / 3,
          -this.height / 3,
          0,
          1000,
        );
        this.camera.position.z = 10;
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xffffff);
        this.scene.add(this.camera);
        this.stage = new THREE.Group();
        this.scene.add(this.stage);
        this.addPlane();
        webgl.renderer?.render(this.scene, this.camera);
      },
      addPlane() {
        this.plane.geometry = new THREE.PlaneGeometry(1, 1, 1, 1);
        this.plane.material = new THREE.ShaderMaterial({
          transparent: true,
          side: THREE.DoubleSide,
          uniforms: {
            tDiffuse: { value: this.plane.a.texture },
            resolution: { value: new THREE.Vector2() },
            time: { value: 0 },
            width: { value: 1 },
            height: { value: 1.1 },
            speed: { value: 0.1 },
          },
          vertexShader: `
          varying vec2 vUv;
          void main() {
            vUv = uv;
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
          fragmentShader: `
        vec3 mod289(vec3 x) {
          return x - floor(x * (1.0 / 289.0)) * 289.0;
        }
        vec4 mod289(vec4 x) {
          return x - floor(x * (1.0 / 289.0)) * 289.0;
        }
        vec3 permute(vec3 x) {
          return mod289(((x * 34.0) + 1.0) * x);
        }
        vec4 permute(vec4 x) {
          return mod289(((x * 34.0) + 1.0) * x);
        }
        vec4 taylorInvSqrt(vec4 r) {
          return 1.79284291400159 - 0.85373472095314 * r;
        }
        float simplexNoise(vec3 v) {
          const vec2 C = vec2(1.0 / 6.0, 1.0 / 3.0);
          const vec4 D = vec4(0.0, 0.5, 1.0, 2.0);
          vec3 i = floor(v + dot(v, C.yyy));
          vec3 x0 = v - i + dot(i, C.xxx);
          vec3 g = step(x0.yzx, x0.xyz);
          vec3 l = 1.0 - g;
          vec3 i1 = min(g.xyz, l.zxy);
          vec3 i2 = max(g.xyz, l.zxy);
          vec3 x1 = x0 - i1 + C.xxx;
          vec3 x2 = x0 - i2 + C.yyy;
          vec3 x3 = x0 - D.yyy;
          i = mod289(i);
          vec4 p = permute(permute(permute(
            i.z + vec4(0.0, i1.z, i2.z, 1.0)) +
            i.y + vec4(0.0, i1.y, i2.y, 1.0)) +
            i.x + vec4(0.0, i1.x, i2.x, 1.0));
          float n_ = 0.142857142857;
          vec3 ns = n_ * D.wyz - D.xzx;
          vec4 j = p - 49.0 * floor(p * ns.z * ns.z);
          vec4 x_ = floor(j * ns.z);
          vec4 y_ = floor(j - 7.0 * x_);
          vec4 x = x_ * ns.x + ns.yyyy;
          vec4 y = y_ * ns.x + ns.yyyy;
          vec4 h = 1.0 - abs(x) - abs(y);
          vec4 b0 = vec4(x.xy, y.xy);
          vec4 b1 = vec4(x.zw, y.zw);
          vec4 s0 = floor(b0) * 2.0 + 1.0;
          vec4 s1 = floor(b1) * 2.0 + 1.0;
          vec4 sh = -step(h, vec4(0.0));
          vec4 a0 = b0.xzyw + s0.xzyw * sh.xxyy;
          vec4 a1 = b1.xzyw + s1.xzyw * sh.zzww;
          vec3 p0 = vec3(a0.xy, h.x);
          vec3 p1 = vec3(a0.zw, h.y);
          vec3 p2 = vec3(a1.xy, h.z);
          vec3 p3 = vec3(a1.zw, h.w);
          vec4 norm = taylorInvSqrt(vec4(dot(p0, p0), dot(p1, p1), dot(p2, p2), dot(p3, p3)));
          p0 *= norm.x;
          p1 *= norm.y;
          p2 *= norm.z;
          p3 *= norm.w;
          vec4 m = max(0.6 - vec4(dot(x0, x0), dot(x1, x1), dot(x2, x2), dot(x3, x3)), 0.0);
          m = m * m;
          return 42.0 * dot(m * m, vec4(dot(p0, x0), dot(p1, x1), dot(p2, x2), dot(p3, x3)));
        }
        uniform sampler2D tDiffuse;
        varying vec2 vUv;
        uniform float time;
        uniform float width;
        uniform float height;
        uniform float speed;
        void main() {
          float n = simplexNoise(vec3(vUv.x * width, vUv.y * height, time * speed * 0.01));
          float r = float(0.5 * n);
          float g = float(0.5 * n);
          float b = float(0.5 * n);
          gl_FragColor = texture2D(tDiffuse, vUv + vec2(0.5 * n));
        }
        `,
        });
        (this.plane.material.uniforms.resolution.value as THREE.Vector2).x =
          this.width * webgl.pixelRatio;
        (this.plane.material.uniforms.resolution.value as THREE.Vector2).y =
          this.height * webgl.pixelRatio;
        this.plane.mesh = new THREE.Mesh(
          this.plane.geometry,
          this.plane.material,
        );
        this.plane.mesh.scale.set(this.width, this.height, 1);
        this.stage?.add(this.plane.mesh);
      },
      update() {
        if (this.plane.material) this.plane.material.uniforms.time.value += 0.5; // アニメーション速度の調整
      },
    };

    webgl.init();
    webgl.glTex.loadAssets();
    setTimeout(() => {
      setBgLoaded(true);
    }, 1000);

    const resize = () => {
      webgl.resize();
    };

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const matches = useMatches();
  const outlet = useOutlet();

  return (
    <div
      css={css`
        width: 100%;
        overflow: hidden;
      `}
    >
      <main
        css={css`
          width: 100%;
          margin: 0 auto;
        `}
      >
        {windowHeight < 500 && (
          <div
            css={css`
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              z-index: 999;
              background-color: #000;
              opacity: 0.75;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <p
              css={css`
                font-size: 1.15rem;
                text-align: center;
                color: #fff;
              `}
            >
              画面の高さを500px以上にしてご覧ください
            </p>
          </div>
        )}
        <div
          className="bgArea"
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 0;
            background-color: #1b3150;
          `}
        >
          <canvas id="js-webgl" data-src="/img/bg.jpg"></canvas>
          <div
            css={css`
              background-image: url(/img/noise.jpg);
              background-repeat: repeat;
              background-size: 256px;
              width: 100vw;
              height: 100vh;
              position: fixed;
              left: 0;
              top: 0;
              mix-blend-mode: soft-light;
              opacity: 0.5;
              z-index: 1;
            `}
          ></div>
        </div>
        <div
          css={css`
            width: calc(100% - min(9vw, 4rem));
            position: fixed;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            height: clamp(90px, 15vh, 150px);
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <h1
            css={css`
              height: 100%;
              padding: min(3.5vh, 2.5rem) 0;
            `}
          >
            <Link
              to="/"
              css={css`
                text-decoration: none;
                display: block;
                height: 100%;
                width: auto;
              `}
            >
              <svg
                css={css`
                  height: 100%;
                  width: auto;
                  max-width: 40vw;
                `}
                className={`${bgLoaded ? "active" : ""}`}
                version="1.1"
                id="title-svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 429 157"
                enableBackground={"new 0 0 429 157"}
                xmlSpace="preserve"
                width="429"
                height="157"
              >
                <g>
                  <path
                    className="st0 svg-elem-1"
                    d="M35.8,5.3h16.7c12,0,23.2,4.6,23.2,17.5c0.1,14.1-10.3,20.4-26.2,20.4h-5.7l-0.2,34.6h-7.9C35.8,77.8,35.8,5.3,35.8,5.3z M49.7,42.1c11.3,0,18.7-6.7,18.6-19.3C68.2,11.5,61.9,6.5,52.9,6.5h-9.2l0.2,35.6H49.7z"
                  ></path>
                  <path
                    className="st0 svg-elem-2"
                    d="M76.7,52.1c0-14.4,11.4-26,26.3-26c15,0,26.4,11.6,26.4,26c0,15.4-11.5,26.4-26.4,26.4S76.7,67.5,76.7,52.1zM121.3,52.1c0-12.8-5.9-24.9-18.4-24.9s-18.3,12-18.3,24.9c0,12.7,5.9,25.2,18.3,25.2C115.5,77.4,121.3,64.8,121.3,52.1z"
                  ></path>
                  <path
                    className="st0 svg-elem-3"
                    d="M138,28.2l7.8-1.9v51.5H138V28.2z M144.4,46.8c0.9-13.2,8.5-20.7,17.8-20.7c1.9,0,4.4,0.4,6,0.9l-0.4,1.1c-1.5-0.5-3.9-0.9-5.8-0.9c-8.7,0-16,8.2-16.3,20L144.4,46.8z"
                  ></path>
                  <path
                    className="st0 svg-elem-4"
                    d="M179.9,60.1v-31h-6.6V28c6.2,0,12.5-3.6,13.3-13.3h1.1V28h13.6v1.1h-13.6v30.6c0,12.4,2.6,17,9.4,18l-0.1,0.8C187.6,77.6,179.9,73,179.9,60.1z"
                  ></path>
                  <path
                    className="st0 svg-elem-5"
                    d="M206.6,27.9h28.9V29h-28.9V27.9z M212.6,26.1c0-13.9,5-23.1,17.1-23.1c8.9,0,13.9,5.7,13.9,11.5h-1.1c0-5.1-4.5-10.3-12.6-10.3c-8.3,0-13.5,4.6-13.5,13.9c0,4.1,1.6,8.3,4.1,9.9v49.9h-7.8L212.6,26.1L212.6,26.1z"
                  ></path>
                  <path
                    className="st0 svg-elem-6"
                    d="M237.2,52.1c0-14.4,11.4-26,26.3-26c15,0,26.4,11.6,26.4,26c0,15.4-11.5,26.4-26.4,26.4C248.7,78.5,237.2,67.5,237.2,52.1z M281.9,52.1c0-12.8-5.9-24.9-18.4-24.9s-18.3,12-18.3,24.9c0,12.7,5.9,25.2,18.3,25.2C276,77.4,281.9,64.8,281.9,52.1z"
                  ></path>
                  <path
                    className="st0 svg-elem-7"
                    d="M300.6,5.6l7.7-1.9v74.1h-7.7V5.6z"
                  ></path>
                  <path
                    className="st0 svg-elem-8"
                    d="M321.1,14.6c0-2.8,2.2-5,5.1-5c2.8,0,4.9,2.2,4.9,5s-2.2,4.9-4.9,4.9C323.3,19.6,321.1,17.4,321.1,14.6zM322.3,28.6l7.8-1.9v51.2h-7.8V28.6z"
                  ></path>
                  <path
                    className="st0 svg-elem-9"
                    d="M342.4,52.1c0-14.4,11.4-26,26.3-26c15,0,26.4,11.6,26.4,26c0,15.4-11.5,26.4-26.4,26.4C353.9,78.5,342.4,67.5,342.4,52.1z M387.1,52.1c0-12.8-5.9-24.9-18.4-24.9s-18.3,12-18.3,24.9c0,12.7,5.9,25.2,18.3,25.2C381.2,77.4,387.1,64.8,387.1,52.1z"
                  ></path>
                </g>
                <g className="st1">
                  <path
                    className="st0 svg-elem-10"
                    d="M2.8,109h8.4c6.3,0,11.6,1.6,11.6,6.7c0,4.8-5.8,7.6-10.3,7.7v-0.1c8.2-0.4,16.2,2.8,16.2,9.9c0,7.5-6.4,11.3-16.5,11.3H2.8V109z M9.8,123.1c5.7,0,9.3-2.3,9.3-7.3c0-3.8-2.4-6.2-8.4-6.2H7.1v13.6L9.8,123.1L9.8,123.1zM12.7,143.9c7.1,0,11.7-3,11.7-10.6c0-6.5-4.1-9.6-13.1-9.6H7.1v20.2H12.7L12.7,143.9z"
                  ></path>
                  <path
                    className="st0 svg-elem-11"
                    d="M32.5,119.5H37l10.5,21.2l-0.7,0.1l10.5-21.3H58l-12.3,25l-0.7,0.2L32.5,119.5z M45,144.5h0.7l-4.9,10.1h-0.7L45,144.5z"
                  ></path>
                  <path
                    className="st0 svg-elem-12"
                    d="M94.4,110.8l0.5,0.4l-16.2,33.3H78L94.4,110.8z M87.4,125.5h16v0.6h-16V125.5z M93,109h4.8l17.4,35.4h-4.7L93,109z"
                  ></path>
                  <path
                    className="st0 svg-elem-13"
                    d="M119.3,131.9c0-7.1,6.2-12.7,14.4-12.7s14.4,5.7,14.4,12.7c0,7.5-6.3,12.9-14.4,12.9C125.6,144.8,119.3,139.4,119.3,131.9z M143.7,131.9c0-6.3-3.2-12.2-10.1-12.2c-6.8,0-10,5.9-10,12.2c0,6.2,3.2,12.3,10,12.3C140.5,144.2,143.7,138.1,143.7,131.9z"
                  ></path>
                  <path
                    className="st0 svg-elem-14"
                    d="M156.3,135.8v-15.2h-3.6V120c3.4,0,6.9-1.8,7.3-6.5h0.6v6.5h7.4v0.6h-7.5v14.9c0,6.1,1.4,8.3,5.1,8.8l-0.1,0.4C160.5,144.3,156.3,142.1,156.3,135.8z"
                  ></path>
                  <path
                    className="st0 svg-elem-15"
                    d="M171.2,131.9c0-7.1,6.2-12.7,14.4-12.7s14.4,5.7,14.4,12.7c0,7.5-6.3,12.9-14.4,12.9C177.5,144.8,171.2,139.4,171.2,131.9z M195.7,131.9c0-6.3-3.2-12.2-10.1-12.2s-10,5.9-10,12.2c0,6.2,3.2,12.3,10,12.3C192.5,144.2,195.7,138.1,195.7,131.9z"
                  ></path>
                  <path
                    className="st0 svg-elem-16"
                    d="M223.6,109h1.4v1.9L223.6,109z M224.9,109h3.6l15.1,23.7h-0.3l16.2-23.7h3v35.4h-4.3V111l1.1-0.8l-17.4,25.5h-1.3L225,111l0.5-0.1v33.6h-0.6V109L224.9,109z"
                  ></path>
                  <path
                    className="st0 svg-elem-17"
                    d="M271.7,132.3c0-7.9,6.6-13.2,14.6-13.2c7.5,0,11.3,4.2,9.7,8.5h-21V127h13.1c2.4,0,4.5-0.5,4.5-2.8c0-2.6-2.2-4.6-6.3-4.6c-6.2,0-10.1,4.3-10.1,11.7c0,7.7,4,12.5,10.9,12.5c4.3,0,8.4-2.4,9.5-7.3l0.6,0.1c-1.2,5.1-5.7,8.2-12,8.2C277.9,144.9,271.7,140.2,271.7,132.3z"
                  ></path>
                  <path
                    className="st0 svg-elem-18"
                    d="M302.4,145.7c0-2.3,1-4.3,2.2-5.5l0.5,0.3c-1.1,1.1-2.1,2.9-2.1,5.1c0,4.8,4.3,8.6,11.5,8.7v0.6C306.9,154.9,302.4,150.8,302.4,145.7z M304.8,125.6c0-3.7,4-6.4,9.2-6.4c5.4,0,9.3,2.7,9.3,6.4c0,3.8-3.9,6.6-9.3,6.6C308.7,132.1,304.8,129.3,304.8,125.6z M314.5,154.4c7,0,10.7-3,10.7-6.5s-2.5-4.8-11.2-7.5c-6.6-2-8.7-3.1-8.7-5.9c0-1.8,1.5-3.5,3.1-4.2l0.5,0.4c-1.4,0.5-2.1,1.5-2.1,2.2c0,1,0.6,1.7,7.6,3.7c9.3,2.6,12.4,4.6,12.4,9.1c0,5.8-4.6,9.3-12.2,9.3L314.5,154.4L314.5,154.4z M319.7,125.6c0-3.2-1.7-5.9-5.6-5.9s-5.6,2.8-5.6,5.9c0,3.2,1.8,6.1,5.6,6.1C318,131.7,319.7,128.7,319.7,125.6z M318.1,120.5c0.9-1.9,2.8-2.7,4.6-2.7c1.1,0,2.4,0.2,3.6,1.6l-0.5,0.3c-0.9-1-2.2-1.3-3.2-1.3c-1.7,0-3.3,0.8-4,2.6L318.1,120.5z"
                  ></path>
                  <path
                    className="st0 svg-elem-19"
                    d="M333.5,134.5v-14.3l4.2-0.9v15.6c0,6,1.9,9,7.5,9c3.4,0,8.5-2.5,8.5-8l0.6-0.1c0,6.3-6.1,9-10.8,9C337.5,144.8,333.5,141.9,333.5,134.5z M353.8,120.2l4.2-0.9v25.2h-4.2V120.2z"
                  ></path>
                  <path
                    className="st0 svg-elem-20"
                    d="M368.3,120.2l4.3-0.9v25.2h-4.3V120.2z M387.4,128.7c0-5.4-1.5-8.6-6.7-8.6c-3.7,0-8.1,2.5-8.1,8l-0.6,0.1c0-6.3,5.2-9,10.4-9c6.1,0,9.2,2.9,9.2,9.4v15.9h-4.2L387.4,128.7L387.4,128.7z M406.4,128.7c0-5.4-1.5-8.6-6.7-8.6c-3.8,0-8.1,2.5-8.1,8l-0.6,0.1c0-6.3,5.2-9,10.4-9c6.1,0,9.2,2.9,9.2,9.4v15.9h-4.2L406.4,128.7L406.4,128.7z"
                  ></path>
                  <path
                    className="st0 svg-elem-21"
                    d="M420.5,113.6c0-1.4,1.2-2.5,2.8-2.5c1.5,0,2.7,1.1,2.7,2.5c0,1.3-1.2,2.4-2.7,2.4C421.7,116,420.5,114.9,420.5,113.6z M421.2,120.4l4.3-0.9v25h-4.3V120.4z"
                  ></path>
                </g>
              </svg>
            </Link>
          </h1>
          <motion.div
            initial={{ width: 0 }}
            animate={{
              width: bgLoaded ? "100%" : 0,
              transition: {
                duration: 1,
                ease: "easeInOut",
              },
            }}
            css={css`
              position: absolute;
              width: 0;
              height: 1px;
              background-color: #fff;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            `}
          ></motion.div>
        </div>
        <motion.div
          initial={{ height: 0 }}
          animate={{
            height: bgLoaded ? "100vh" : 0,
            transition: {
              duration: 1,
              ease: "easeInOut",
            },
          }}
          css={css`
            width: calc(100% - min(9vw, 4rem));
            height: 0;
            position: fixed;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            pointer-events: none;
          `}
        ></motion.div>
        <AnimatePresence mode="wait">
          <React.Fragment key={matches[1].pathname}>{outlet}</React.Fragment>
        </AnimatePresence>
      </main>
    </div>
  );
}

export default App;
