import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project08() {
  const title = "ロボットリース会社ホームページ";
  const tags = ["受託制作", "デザイン", "コーディング"];
  const url = "https://tgcyber-tech-japan.com";
  const type = "静的サイト（Next.jsからStaticExportsで書き出し）";
  const used = ["HTML(JSX)", "CSS(SCSS)", "JavaScript", "Adobe XD"];
  const texts = [
    "ロボットリース会社の日本支社のホームページを制作しました。<br>日本支社の事業活動がこれからという状況で、サイト内容の変更や追加が予想されるため、シンプルなレイアウトとしています。",
    "サーバーのレンタルやドメインの取得の他、メールアカウントの設定も併せて代行しました。",
    "環境構築にはcreate-next-appを使用し、Next.jsからStaticExportsで書き出しを行い、静的サイトとして公開しています。",
  ];
  const img = "tg";
  const width = 1200;
  const height = 4118;
  const year = "2023年";

  const theme = useTheme();
  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      width={width}
      height={height}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        下層ページ
      </h3>
      <div
        css={css`
          margin-top: 1rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1.5rem;
          row-gap: 1.5rem;
          @media (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(1, 1fr);
          }
        `}
      >
        <picture>
          <source srcSet="img/tg3.webp" type="image/webp" />
          <img
            src="img/tg3.jpg"
            alt="ロボットリース会社ホームページの下層ページ1"
            width={1200}
            height={3992}
          />
        </picture>
        <picture>
          <source srcSet="img/tg2.webp" type="image/webp" />
          <img
            src="img/tg2.jpg"
            alt="ロボットリース会社ホームページの下層ページ2"
            width={1200}
            height={4510}
          />
        </picture>
      </div>
    </Layout2>
  );
}
