import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout from "../components/layout/Layout";

const listStyle = css({
  margin: "0.2rem 0",
  padding: "0 0 0 1rem",
  position: "relative",
  lineHeight: 1.5,
  letterSpacing: "0.1em",
  "&::before": {
    content: "''",
    position: "absolute",
    top: "0.65rem",
    left: 0,
    width: "0.4rem",
    height: "0.4rem",
    borderRadius: "999px",
    backgroundColor: "#fff",
  },
});

const textStyle = css({
  margin: "0.75rem 0 0",
  lineHeight: 1.65,
  fontSize: "0.9375rem",
});

const h4Style = css({
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: 1.65,
  margin: "1rem 0 0",
});

const h3Style = css({
  fontSize: "clamp(1.15rem, 5vw, 1.4rem)",
  fontWeight: 700,
  lineHeight: 1.4,
  paddingBottom: "0.5rem",
  borderBottom: "1px solid #fff",
  paddingLeft: "2rem",
  textIndent: "-2.25rem",
});

export default function Project02() {
  const title = "Pythonによる社内の業務改善";
  const tags = ["業務改善", "Python"];
  const type = "デスクトップアプリ";
  const used = ["Python"];
  const texts = [
    "手作業や外注で行っていたデータ成形作業や、日常業務での小さな困りごとをPythonを利用して改善しました。",
    "これらの改善は主に他部署の業務であり、自部門の範疇ではありませんでしたが、話を聞く中で自分の方で改善できると考え、自主的に取り組んだものです。",
  ];
  const img = "python_tmb";
  const year = "2022~2023年";

  const theme = useTheme();

  return (
    <Layout
      title={title}
      tags={tags}
      texts={texts}
      img={img}
      used={used}
      type={type}
      year={year}
    >
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <picture
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
        >
          <source srcSet="img/python5.webp" type="image/webp" />
          <img
            src="img/python5.jpg"
            alt="毎月のサイト会員情報の更新用CSVファイルの作成"
            width={1200}
            height={800}
          />
        </picture>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>１. 毎月のサイト会員データの更新用CSVの作成</h3>
          <p className="font-jp" css={textStyle}>
            サイトの会員情報を更新するためのCSVデータ作成作業をPythonで自動化しました。
          </p>
          <p className="font-jp" css={textStyle}>
            お客様から送付されるExcelと次月のパスワード、現行サイトの会員情報を利用し、データベース更新用の4つのCSVファイルを作成します。
          </p>
          <p className="font-jp" css={textStyle}>
            Shift_JISからUTF-8に変換した際の非対応漢字の置き換えや、
            連結されている姓名を分割したりと、お客様側で利用しているExcelデータをそのままサイトのデータベースには利用できないため、加工が必要です。
          </p>
          <p className="font-jp" css={textStyle}>
            約1万人分の会員データを整え、CSVを作成するのに毎月3時間かかっていましたが、GUI操作で、誰でも5分でCSVファイルを作成できるように改善しました。
          </p>
          <h4 css={h4Style}>【主な使用ライブラリ】</h4>
          <ul>
            <li css={listStyle}>Pandas（Excel・CSV操作）</li>
            <li css={listStyle}>Tkinter（GUI）</li>
            <li css={listStyle}>Pyinstaller（exe化）</li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <picture
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
        >
          <source srcSet="img/python2.webp" type="image/webp" />
          <img
            src="img/python2.jpg"
            alt="毎月の掃除分担表の作成"
            width={1200}
            height={800}
          />
        </picture>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>２. 毎月の掃除分担表の作成</h3>
          <p className="font-jp" css={textStyle}>
            毎朝15分、全社で6グループに分かれて掃除をしています。
          </p>
          <p className="font-jp" css={textStyle}>
            各グループのリーダーは、毎月掃除分担表を作成していましたが、掃除場所やメンバーの変更、同じ人が同じ場所を掃除しないように調整したりと、作成に毎月30分以上かけていることもありました。
          </p>
          <p className="font-jp" css={textStyle}>
            また、毎月の社長の清掃点検時にグループごとに分担表の書式がバラバラのため、チェックしにくいという問題もありました。
          </p>
          <p className="font-jp" css={textStyle}>
            そこで、この作業をPythonで自動化し、私が全グループの分担表を一括作成することで、リーダーの負担を減らし、書式の統一を図りました。
          </p>
          <h4 css={h4Style}>【主な使用ライブラリ】</h4>
          <ul>
            <li css={listStyle}>Openpyxl（Excel操作）</li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <picture
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
        >
          <source srcSet="img/python3.webp" type="image/webp" />
          <img
            src="img/python3.jpg"
            alt="InDesignへの流し込み用CSVの作成"
            width={1200}
            height={800}
          />
        </picture>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>３. InDesignへの流し込み用CSVの作成</h3>
          <p className="font-jp" css={textStyle}>
            印刷会社のため、機関紙やカタログ、抄録集など、固定レイアウトを繰り返す印刷データをInDesignで作成することがよくあります。
          </p>
          <p className="font-jp" css={textStyle}>
            InDesignにはCSVを利用してレイアウトにテキストを流し込む機能があるため、お客様から送られた原稿のExcelデータからInDesignに取り込めるCSVを生成する作業をPythonで行いました。
          </p>
          <p className="font-jp" css={textStyle}>
            Excelの他に、原稿はサイトから取得して欲しいという場合には、PythonでスクレイピングしてCSVを作成しました。
          </p>
          <h4 css={h4Style}>【主な使用ライブラリ】</h4>
          <ul>
            <li css={listStyle}>Pandas（Excel・CSV操作）</li>
            <li css={listStyle}>Openpyxl（Excel操作）</li>
            <li css={listStyle}>BeautifulSoup（WEBスクレイピング）</li>
            <li css={listStyle}>Selenium（WEBスクレイピング）</li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          max-width: 1500px;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          @media (max-width: ${theme.breakpoints.md}) {
            display: block;
          }
        `}
      >
        <video
          css={css`
            width: 45%;
            height: 100%;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              height: 100%;
            }
          `}
          autoPlay
          playsInline
          loop
          muted
          width={782}
          height={538}
          preload="auto"
          poster="img/python4.webp"
        >
          <source src="img/python4.webm" type="video/webm" />
          <source src="img/python4.mp4" type="video/mp4" />
        </video>
        <div
          css={css`
            width: 55%;
            padding: 0 0 0 2rem;
            @media (max-width: ${theme.breakpoints.md}) {
              width: 100%;
              padding: 1rem 0 0 0;
            }
          `}
        >
          <h3 css={h3Style}>４. 日常業務の作業の一括処理</h3>
          <p className="font-jp" css={textStyle}>
            日常業務でよくある作業をPythonで自動化し、社内で誰でも使えるようにexe化しました。
          </p>
          <p className="font-jp" css={textStyle}>
            最初は、営業用PCにはAdobe
            Acrobatがないため、複数のPDFファイルを画像化するのが手間という問題があり、PDFを一度に画像化する機能を作りました。
          </p>
          <p className="font-jp" css={textStyle}>
            その後、要望をヒアリングし、画像のリサイズやファイルのリネームなどの機能を追加しました。
          </p>
          <h4 css={h4Style}>【主な使用ライブラリ】</h4>
          <ul>
            <li css={listStyle}>Pillow（画像処理）</li>
            <li css={listStyle}>Pdf2image（PDFの画像処理）</li>
            <li css={listStyle}>Tkinter（GUI）</li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}
