import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@emotion/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CommonContextProvider } from "./context/CommonContext";
import "./index.css";
import App from "./App";
import Home from "./pages/Home";
import theme from "./theme";
import Project01 from "./pages/Project01";
import Project02 from "./pages/Project02";
import Project03 from "./pages/Project03";
import Project04 from "./pages/Project04";
import Project05 from "./pages/Project05";
import Project09 from "./pages/Project09";
import Project10 from "./pages/Project10";
import Project11 from "./pages/Project11";
import Project12 from "./pages/Project12";
import Project06 from "./pages/Project06";
import Project07 from "./pages/Project07";
import Project08 from "./pages/Project08";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "project01",
        element: <Project01 />,
      },
      {
        path: "project02",
        element: <Project02 />,
      },
      {
        path: "project03",
        element: <Project03 />,
      },
      {
        path: "project04",
        element: <Project04 />,
      },
      {
        path: "project05",
        element: <Project05 />,
      },
      {
        path: "project06",
        element: <Project06 />,
      },
      {
        path: "project07",
        element: <Project07 />,
      },
      {
        path: "project08",
        element: <Project08 />,
      },
      {
        path: "project09",
        element: <Project09 />,
      },
      {
        path: "project10",
        element: <Project10 />,
      },
      {
        path: "project11",
        element: <Project11 />,
      },
      {
        path: "project12",
        element: <Project12 />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <CommonContextProvider>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </ThemeProvider>
  </CommonContextProvider>,
);
