import { Variants } from "framer-motion";

export const parentVariant: Variants = {
  initial: { opacity: 0, y: 50 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.5, ease: "circOut" },
  },
};

export const childrenVariant: Variants = {
  initial: { opacity: 0, y: 50 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      y: { duration: 0.4, ease: "circOut" },
      opacity: { duration: 0.4, ease: "circOut" },
    },
  },
};
