import React, { useEffect, useRef, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { childrenVariant, parentVariant } from "../../constant";

gsap.registerPlugin(ScrollTrigger);

interface LayoutProps {
  title: string;
  tags: string[];
  used: string[];
  type: string;
  url?: string;
  pass?: string;
  img: string;
  year: string;
  texts: string[];
  github?: string;
  children: React.ReactNode;
}

const usedListStyle = css({
  margin: "0.5rem 0 0",
  "&::after": {
    content: "'/'",
    padding: "0 0.5rem",
  },
});

const Layout = ({
  title,
  tags,
  used,
  url,
  img,
  texts,
  type,
  github,
  pass,
  children,
  year,
}: LayoutProps) => {
  const theme = useTheme();
  const [isTextAnimating, setIsTextAnimating] = useState(true);
  const pagesWrapperRef = useRef<HTMLDivElement | null>(null);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const pagesRef = useRef<HTMLDivElement | null>(null);

  const setupGsap = (
    pagesElement: HTMLDivElement,
    pagesWrapperElement: HTMLDivElement,
  ) => {
    // 既存のScrollTriggerインスタンスをクリア
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    gsap.to(pagesElement, {
      y: () => -(pagesElement.clientHeight - pagesWrapperElement.clientHeight),
      ease: "none",
      scrollTrigger: {
        trigger: "#vertical-scroll-section",
        start: "top top",
        end: () =>
          `+=${pagesElement.clientHeight - pagesWrapperElement.clientHeight}`,
        scrub: true,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
        pinSpacing: false,
        onUpdate: (self) => {
          // ScrollTrigger のインスタンスから pin-spacer を取得
          const pinSpacer = self.pin?.parentNode as HTMLElement;
          if (pinSpacer) {
            // pinSpacer の padding-bottom を設定
            pinSpacer.style.paddingBottom = `calc(${pagesElement.clientHeight}px + clamp(90px, 15vh, 150px))`;
          }
        },
      },
    });
    setWindowSize(window.innerWidth);
  };

  const setScrollbarWidth = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    // カスタムプロパティの値を更新する
    document.documentElement.style.setProperty(
      "--scrollbar",
      `${scrollbarWidth}px`,
    );
  };

  useEffect(() => {
    const pagesElement = pagesRef?.current;
    if (!pagesElement) return;
    const pagesWrapperElement = pagesWrapperRef?.current;
    if (!pagesWrapperElement) return;

    // 画像と動画のロードを監視する関数
    const waitForMediaToLoad = (
      element: HTMLDivElement,
      callback: () => void,
    ) => {
      const images = element.querySelectorAll("img");
      const videos = element.querySelectorAll("video");
      let loadedCount = 0;
      const totalMedia = images.length + videos.length;

      if (totalMedia === 0) {
        // メディアがない場合はすぐにcallbackを呼び出す
        callback();
        return;
      }

      const checkLoaded = () => {
        loadedCount += 1;
        if (loadedCount === totalMedia) {
          callback();
        }
      };

      images.forEach((imgElement) => {
        if (imgElement.complete) {
          // すでに読み込まれている画像
          checkLoaded();
        } else {
          // 画像が読み込まれていない場合はloadイベントを監視
          imgElement.addEventListener("load", checkLoaded);
          imgElement.addEventListener("error", checkLoaded);
        }
      });

      videos.forEach((videoElement) => {
        if (videoElement.readyState >= 4) {
          // すでに読み込まれている動画
          checkLoaded();
        } else {
          // 動画が読み込まれていない場合はloadeddataイベントを監視
          videoElement.addEventListener("loadeddata", checkLoaded);
          videoElement.addEventListener("error", checkLoaded);
        }
      });

      // タイムアウトを設定
      setTimeout(() => {
        if (loadedCount < totalMedia) {
          callback();
        }
      }, 4000);
    };

    // 画像が読み込まれてからsetupGsapを呼び出す
    waitForMediaToLoad(pagesElement, () => {
      setupGsap(pagesElement, pagesWrapperElement);
      setScrollbarWidth();
    });

    const handleResize = () => {
      if (windowSize !== window.innerWidth) {
        setupGsap(pagesElement, pagesWrapperElement);
        ScrollTrigger.refresh(); // ScrollTriggerの計算をリフレッシュ
      }
      setScrollbarWidth();
    };

    // ウィンドウのリサイズイベントを監視
    window.addEventListener("resize", handleResize);

    /* eslint-disable */
    return () => {
      // コンポーネントがアンマウントされたときにクリア
      window.removeEventListener("resize", handleResize);
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
    /* eslint-disable */
  }, []);

  return (
    <>
      <motion.div
        css={css`
          height: clamp(90px, 15vh, 150px);
          width: clamp(90px, 15vh, 150px);
          position: fixed;
          top: 0;
          left: min(4vw, 2rem);
          z-index: 10;
          padding: min(2vh, 1.5rem);
        `}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.35,
            delay: 0.75,
            ease: "easeInOut",
          },
        }}
        exit={{
          opacity: 0,
          transition: { duration: 0.35, ease: "easeInOut" },
        }}
      >
        <Link
          to="/"
          title="一覧へ戻る"
          css={css`
            display: flex;
            align-items: center;
            justify-content: left;
            height: 100%;
          `}
        >
          <div
            className="backButton"
            css={css`
              width: 100%;
              height: 100%;
              max-width: 15vw;
              max-height: 15vw;
              position: relative;
              display: flex
              justify-content: center;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39.186"
              height="33.833"
              viewBox="0 0 39.186 33.833"
              css={css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 40%;
                height: auto;
                object-fit: contain;
              `}
            >
              <path
                d="M-18.346,1.5c0,1.788,1.772,4.464,3.564,6.712a31.756,31.756,0,0,0,8.22,7.352C-4.2,17.009-1.321,18.4.987,18.4M-18.346,35.333c0-1.788,1.772-4.464,3.564-6.712a31.756,31.756,0,0,1,8.22-7.351c2.367-1.445,5.241-2.832,7.55-2.832m0-.021L-38.2,18.4"
                transform="translate(0.988 35.333) rotate(180)"
                fill="none"
                stroke="#fff"
                strokeWidth="1"
              />
            </svg>
            <svg
              className="arrowIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 50 50"
            >
              <g
                fill="none"
                stroke="#fff"
                strokeWidth="0.5"
                strokeLinejoin="round"
                strokeMiterlimit="10"
              >
                <circle
                  className="arrowIcon-circle"
                  cx="25"
                  cy="25"
                  r="24.5"
                ></circle>
              </g>
            </svg>
          </div>
        </Link>
      </motion.div>
      <motion.div
        exit={{
          opacity: 0,
          transition: { duration: 0.4, ease: "easeInOut" },
        }}
        css={css`
          min-height: calc(100vh + 1px);
        `}
      >
        <section
          css={css`
            width: 100vw;
            height: 100vh;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            transform: translate(0px, 0px) !important;
            padding-top: clamp(90px, 15vh, 150px);
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
          `}
          id="vertical-scroll-section"
        >
          <div
            css={css`
              width: calc(100% - 4rem);
              height: 100%;
              position: relative;
              margin: 0 auto 0;
              height: calc(100vh - clamp(90px, 15vh, 150px));
              overflow: hidden;
            `}
            ref={pagesWrapperRef}
          >
            <div
              css={css`
                height: auto;
                width: calc(100% - var(--scrollbar));
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: min(6vw, 3rem) min(4vw, 2rem);
                overflow: hidden;
              `}
              ref={pagesRef}
              id="pages"
            >
              <motion.div
                css={css`
                  max-width: 1500px;
                  display: flex;
                  justify-content: space-between;
                  @media (max-width: ${theme.breakpoints.md}) {
                    display: block;
                  }
                `}
                variants={parentVariant}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <motion.picture
                  css={css`
                    width: 45%;
                    @media (max-width: ${theme.breakpoints.md}) {
                      width: 100%;
                    }
                  `}
                  variants={childrenVariant}
                >
                  <source srcSet={`img/${img}.webp`} type="image/webp" />
                  <img
                    src={`img/${img}.jpg`}
                    alt={`${title}のサムネイル画像`}
                    width={900}
                    height={600}
                  />
                </motion.picture>
                <div
                  css={css`
                    width: 55%;
                    padding: 0 0 0 2rem;
                    @media (max-width: ${theme.breakpoints.md}) {
                      width: 100%;
                      padding: 1rem 0 0 0;
                    }
                  `}
                >
                  <motion.h2
                    css={css`
                      color: #fff;
                      font-weight: 700;
                      font-size: clamp(1.5rem, 5vw, 2.2rem);
                      line-height: 1.35;
                    `}
                    variants={childrenVariant}
                  >
                    {title}
                  </motion.h2>
                  <motion.ul
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      flex-wrap: wrap;
                      margin-bottom: 2.25rem;
                    `}
                    variants={childrenVariant}
                  >
                    {tags.map((tag, index) => (
                      <li
                        css={css`
                          font-size: 1.0625rem;
                          font-weight: 700;
                          margin-right: 0.5rem;
                          margin-top: 0.5rem;
                          padding: 0.25rem 0.5rem 0.25rem;
                          background: #ffffff47;
                          border-radius: 3px;
                          line-height: 1.25;
                        `}
                        key={index}
                      >
                        {tag}
                      </li>
                    ))}
                  </motion.ul>
                  {url && (
                    <>
                      <motion.dl
                        css={css`
                          display: flex;
                          align-items: flex-start;
                          flex-wrap: wrap;
                        `}
                        variants={childrenVariant}
                      >
                        <dt
                          css={css`
                            margin-right: 0.5rem;
                            font-weight: 700;
                            border: 1px solid #fff;
                            padding: 0.25rem 0.5rem 0.35rem;
                            font-size: 0.9375rem;
                          `}
                        >
                          サイトURL
                        </dt>
                        <dd
                          css={css`
                            margin-top: 0.25rem;
                          `}
                        >
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={css`
                              :hover {
                                opacity: 0.6;
                              }
                            `}
                          >
                            {url}
                          </a>
                          {pass && (
                            <>
                              <span
                                css={css`
                                  display: block;
                                  margin-top: 0.5rem;
                                  line-height: 1.4;
                                `}
                                dangerouslySetInnerHTML={{ __html: pass }}
                              />
                            </>
                          )}
                        </dd>
                      </motion.dl>
                    </>
                  )}
                  {github && (
                    <>
                      <motion.dl
                        css={css`
                          display: flex;
                          align-items: flex-start;
                          margin-top: 0.75rem;
                          flex-wrap: wrap;
                        `}
                        variants={childrenVariant}
                      >
                        <dt
                          css={css`
                            margin-right: 0.5rem;
                            font-weight: 700;
                            border: 1px solid #fff;
                            padding: 0.25rem 0.5rem 0.35rem;
                            font-size: 0.9375rem;
                          `}
                        >
                          GitHub
                        </dt>
                        <dd
                          css={css`
                            margin-top: 0.25rem;
                          `}
                        >
                          <a
                            href={github}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={css`
                              :hover {
                                opacity: 0.6;
                              }
                            `}
                          >
                            {github}
                          </a>
                        </dd>
                      </motion.dl>
                    </>
                  )}
                  <motion.dl
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      margin-top: 0.75rem;
                      flex-wrap: wrap;
                    `}
                    variants={childrenVariant}
                  >
                    <dt
                      css={css`
                        margin-right: 0.5rem;
                        font-weight: 700;
                        border: 1px solid #fff;
                        padding: 0.25rem 0.5rem 0.35rem;
                        font-size: 0.9375rem;
                      `}
                    >
                      制作年度
                    </dt>
                    <dd
                      css={css`
                        margin-top: 0.25rem;
                      `}
                    >
                      {year}
                    </dd>
                  </motion.dl>
                  <motion.dl
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      margin-top: 0.75rem;
                      flex-wrap: wrap;
                    `}
                    variants={childrenVariant}
                  >
                    <dt
                      css={css`
                        margin-right: 0.5rem;
                        font-weight: 700;
                        border: 1px solid #fff;
                        padding: 0.25rem 0.5rem 0.35rem;
                        font-size: 0.9375rem;
                      `}
                    >
                      {url ? "サイト形態" : "形態"}
                    </dt>
                    <dd
                      css={css`
                        margin-top: 0.25rem;
                      `}
                    >
                      {type}
                    </dd>
                  </motion.dl>

                  <motion.dl
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      flex-wrap: wrap;
                      margin-top: 0.75rem;
                    `}
                    variants={childrenVariant}
                  >
                    <dt
                      css={css`
                        margin-right: 0.5rem;
                        font-weight: 700;
                        border: 1px solid #fff;
                        padding: 0.25rem 0.5rem 0.35rem;
                        font-size: 0.9375rem;
                        line-height: 1.25;
                      `}
                    >
                      言語・コーティング環境・利用ソフト
                    </dt>
                    {used.map((item, index) => (
                      <dd
                        css={
                          used.length - 1 === index
                            ? css`
                                margin: 0.5rem 0 0;
                              `
                            : usedListStyle
                        }
                        key={index}
                      >
                        {item}
                      </dd>
                    ))}
                  </motion.dl>
                  <motion.div
                    css={css`
                      margin-top: 2rem;
                    `}
                    variants={childrenVariant}
                    onAnimationComplete={() => {
                      setIsTextAnimating(false);
                    }}
                  >
                    {texts.map((text, index) => (
                      <p
                        className="font-jp"
                        css={css`
                          margin: 0.75rem 0 0;
                          line-height: 1.65;
                        `}
                        key={index}
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    ))}
                  </motion.div>
                </div>
              </motion.div>
              <motion.div
                css={css`
                  max-width: 1500px;
                  margin: 3rem auto 0;
                `}
                initial={{ opacity: 0, y: 50 }}
                animate={{
                  y: isTextAnimating ? 50 : 0,
                  opacity: isTextAnimating ? 0 : 1,
                  transition: {
                    y: { duration: 0.4, ease: "circOut" },
                    opacity: { duration: 0.4, ease: "circOut" },
                  },
                }}
              >
                {children}
              </motion.div>
            </div>
          </div>
        </section>
      </motion.div>
    </>
  );
};

export default Layout;
