import React from "react";
/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import Layout2 from "../components/layout/Layout2";

export default function Project12() {
  const title = "木型のECサイト";
  const tags = ["受託制作", "デザイン", "コーディング（テーマのカスタマイズ）"];
  const url = "https://made-in-nihonbashi.myshopify.com";
  const type = "Shopify";
  const used = ["Liquid", "HTML", "CSS", "JavaScript"];
  const texts = [
    "Shopifyのデフォルトテーマをカスタマイズし、木型のECサイトを制作しました。",
    "主にサイト全体のデザイン調整や、オリジナルのコンテンツブロック作成、お客様の要望に応じたShopifyプラグインの選定を行いました。",
    "Shopifyを扱うのは初めてで、作成したことのない機能の要望もあり、調査から取り組むことが多い案件でした。",
    "Shopifyの管理画面からサイトの内容はすべてお客様側で編集できるため、自由にページの作成やレイアウトを変更してご利用いただいています。",
  ];
  const img = "made";
  const year = "2022年";

  const theme = useTheme();

  return (
    <Layout2
      title={title}
      tags={tags}
      url={url}
      texts={texts}
      img={img}
      used={used}
      type={type}
      year={year}
    >
      <h3
        css={css`
          font-size: clamp(1.15rem, 5vw, 1.4rem);
          font-weight: 700;
          line-height: 1.4;
          padding-left: 1.75rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: clamp(0.75rem, 3vw, 0.95rem);
            left: 0;
            width: 1rem;
            height: 1px;
            background-color: #fff;
          }
        `}
      >
        作成したオリジナルのコンテンツブロック（一部）
      </h3>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1rem;
          row-gap: 1rem;
          @media (max-width: ${theme.breakpoints.md}) {
            grid-template-columns: repeat(1, 1fr);
          }
        `}
      >
        <div
          css={css`
            margin-top: 1rem;
          `}
        >
          <picture>
            <source srcSet="img/made2.webp" type="image/webp" />
            <img
              src="img/made2.jpg"
              alt="360度パノラマギャラリーの画像"
              width={1200}
              height={490}
            />
          </picture>
          <h4
            css={css`
              font-size: 1.175rem;
              font-weight: 700;
              border-bottom: 1px solid #fff;
              padding-bottom: 0.25rem;
              margin-bottom: 0.5rem;
              margin-top: 1rem;
              line-height: 1.35;
            `}
          >
            360度パノラマギャラリー
          </h4>
          <p
            className="font-jp"
            css={css`
              margin-top: 0.5rem;
              line-height: 1.65;
              font-size: 0.9rem;
            `}
          >
            工房全体を見れるようにしたいという要望から、Three.jsを利用して、360°カメラで撮影したパノラマ写真を視点を操作しながら見られるブロックをテーマに追加しました。
          </p>
        </div>
        <div
          css={css`
            margin-top: 1rem;
          `}
        >
          <picture>
            <source srcSet="img/made3.webp" type="image/webp" />
            <img
              src="img/made3.jpg"
              alt="木型のデザインプレビューの画像"
              width={1200}
              height={1574}
            />
          </picture>
          <h4
            css={css`
              font-size: 1.175rem;
              font-weight: 700;
              border-bottom: 1px solid #fff;
              padding-bottom: 0.25rem;
              margin-bottom: 0.5rem;
              margin-top: 1rem;
              line-height: 1.35;
            `}
          >
            木型のデザインプレビュー
          </h4>
          <p
            className="font-jp"
            css={css`
              margin-top: 0.5rem;
              line-height: 1.65;
              font-size: 0.9rem;
            `}
          >
            型注文時に、購入者の方との完成イメージのすり合わせをしやすくしたいという要望から、柄の種類、型の形、柄の大きさ、柄の位置をカスタマイズして型のデザインをブラウザ上でプレビューできるブロックをテーマに追加しました。
          </p>
        </div>
      </div>
    </Layout2>
  );
}
